import _extends from "@babel/runtime/helpers/extends";

/** @jsx h */
import { html } from 'htm/preact';
import { h } from 'preact';
export function withHtml(Component) {
  var ComponentWithHtml = function ComponentWithHtml(props) {
    return h(Component, _extends({}, props, {
      html: html
    }));
  };

  if (process.env.NODE_ENV !== 'production') {
    ComponentWithHtml.displayName = "withHtml(".concat(Component.displayName, ")");
  }

  return ComponentWithHtml;
}