import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
var _excluded = ["container", "environment", "itemComponent", "fallbackComponent", "headerComponent", "view", "children"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/** @jsx h */
import { getTrendingFacets } from '@algolia/recommend-core';
import { createTrendingFacetsComponent } from '@algolia/recommend-vdom';
import { html } from 'htm/preact';
import { createElement, Fragment, h, render } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { getHTMLElement } from './getHTMLElement';
import { useAlgoliaAgent } from './useAlgoliaAgent';
import { useStatus } from './useStatus';
import { withHtml } from './utils';
var UncontrolledTrendingFacets = createTrendingFacetsComponent({
  createElement: createElement,
  Fragment: Fragment
});

function useTrendingFacets(props) {
  var _useState = useState({
    recommendations: []
  }),
      _useState2 = _slicedToArray(_useState, 2),
      result = _useState2[0],
      setResult = _useState2[1];

  var _useStatus = useStatus('loading'),
      status = _useStatus.status,
      setStatus = _useStatus.setStatus;

  useAlgoliaAgent({
    recommendClient: props.recommendClient
  });
  useEffect(function () {
    setStatus('loading');
    getTrendingFacets(props).then(function (response) {
      setResult(response);
      setStatus('idle');
    });
  }, [props, setStatus]);
  return _objectSpread(_objectSpread({}, result), {}, {
    status: status
  });
}

function TrendingFacets(props) {
  var _useTrendingFacets = useTrendingFacets(props),
      recommendations = _useTrendingFacets.recommendations,
      status = _useTrendingFacets.status;

  return h(UncontrolledTrendingFacets, _extends({}, props, {
    items: recommendations,
    status: status
  }));
}

export function trendingFacets(_ref) {
  var container = _ref.container,
      _ref$environment = _ref.environment,
      environment = _ref$environment === void 0 ? window : _ref$environment,
      itemComponent = _ref.itemComponent,
      fallbackComponent = _ref.fallbackComponent,
      headerComponent = _ref.headerComponent,
      view = _ref.view,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  var vnode = h(TrendingFacets, _extends({}, props, {
    view: view && withHtml(view),
    itemComponent: itemComponent && withHtml(itemComponent),
    headerComponent: headerComponent && withHtml(headerComponent),
    fallbackComponent: fallbackComponent && withHtml(fallbackComponent)
  }), children ? function (childrenProps) {
    return children(_objectSpread(_objectSpread({}, childrenProps), {}, {
      html: html
    }));
  } : undefined);

  if (!container) {
    return vnode;
  }

  render(vnode, getHTMLElement(container, environment));
  return null;
}