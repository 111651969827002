import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useRef, useState } from 'preact/hooks'; // If you update this logic, please also update the implementation in the
// `recommend-react` package.

export function useStatus(initialStatus) {
  var timerId = useRef(undefined);

  var _useState = useState(initialStatus),
      _useState2 = _slicedToArray(_useState, 2),
      status = _useState2[0],
      setStatus = _useState2[1];

  useEffect(function () {
    if (status !== 'stalled' && timerId.current) {
      clearTimeout(timerId.current);
    }

    if (status === 'loading') {
      timerId.current = setTimeout(function () {
        setStatus('stalled');
      }, 300);
    }
  }, [status]);
  return {
    status: status,
    setStatus: setStatus
  };
}